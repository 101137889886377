import React from 'react';

function ExcelProject() {
  return (
    <div className="excel-container">
      <p className="excelParagraph">Placeholder text for Excel project.</p>
    </div>
  );
}

export default ExcelProject;